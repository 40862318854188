import {
  Box,
  Button,
  FormLabel,
  Heading,
  Input,
  Link,
  Stack,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { getCsrfToken, getProviders, signIn } from "next-auth/react";
import getConfig from "next/config";
import NextLink from "next/link";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { UserAuth } from "src/types/gloually";
import * as Yup from "yup";
const { publicRuntimeConfig } = getConfig();

const LoginSchema = Yup.object().shape({
  password: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
});

const AUTH_ERROR_MESSAGES: Record<string, string> = {
  token_not_found: "Token not found",
  token_expired:
    "Your token has expired. We'll send you a new token valid for 48 hours.",
  invalid_email: "We couldn't find an account with that email.",
  something_went_wrong: "Something went wrong. Please try again.",
  email_verified: "Your email has been verified. Please sign in.",
};

function Login({ csrfToken }: any) {
  const toast = useToast();
  const router = useRouter();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      csrfToken,
    },
    validationSchema: LoginSchema,
    onSubmit: async (values: UserAuth, { setSubmitting }) => {
      await signIn("login", {
        ...values,
        callbackUrl: `${publicRuntimeConfig.GLOUALLY_BASE_URL}/`,
      });

      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (Object.keys(router.query).length > 0) {
      toast({
        status: router.query.error ? "error" : "success",
        title: router.query.error ? "Oops" : "Everything's setup!",
        description:
          AUTH_ERROR_MESSAGES[
            (router.query.error || router.query.success) as string
          ] || "Something went wrong. Please try again.",
        duration: 5000,
        position: "top-right",
      });
    }
  }, [router.query]);

  // @ts-ignore
  const isInvalid = (field) => formik.errors[field] && formik.touched[field];
  const isDisabled = !formik.dirty || !formik.isValid;

  return (
    <>
      <Stack>
        <Heading as="h1" fontSize="3xl" fontWeight={500}>
          Sign in
        </Heading>
        <Text color="surfaceText">
          Need an account?{" "}
          <NextLink href="/signup" passHref>
            <Link color="teal.400">Sign up for free</Link>
          </NextLink>
        </Text>
      </Stack>
      <Stack as="form" mt={4} spacing={4} onSubmit={formik.handleSubmit as any}>
        <Box>
          <FormLabel>E-mail</FormLabel>
          <Input
            variant="filled"
            isInvalid={isInvalid("email")}
            value={formik.values.email}
            onChange={formik.handleChange("email")}
          />
          {formik.errors.email && formik.touched.email && (
            <Text fontSize="sm">{formik.errors.email}</Text>
          )}
        </Box>
        <Box>
          <FormLabel>Password</FormLabel>
          <Input
            variant="filled"
            type="password"
            isInvalid={isInvalid("password")}
            value={formik.values.password}
            onChange={formik.handleChange("password")}
          />
          {formik.errors.password && formik.touched.password && (
            <Text fontSize="sm">{formik.errors.password}</Text>
          )}
        </Box>

        <NextLink href="/reset-password">
          <Link fontSize="sm">Forgot your password?</Link>
        </NextLink>
        <Box>
          <Button
            isLoading={formik.isSubmitting}
            type="submit"
            mt={4}
            colorScheme="teal"
            disabled={isDisabled}
          >
            Login
          </Button>
        </Box>
      </Stack>
      {/* <Stack textAlign="center" mt={4}>
        <DividerWithText>Or</DividerWithText>
        <Button
          mt={8}
          colorScheme="linkedin"
          leftIcon={<FaLinkedinIn />}
          onClick={() => signIn("linkedin")}
        >
          Sign in with Linkedin
        </Button>
      </Stack> */}
    </>
  );
}

export async function getServerSideProps(context: any) {
  const providers = await getProviders();

  return {
    props: {
      csrfToken: await getCsrfToken(context),
      providers,
    },
  };
}

Login.layout = "AuthLayout";

export default Login;
